import React from "react";
import { useState, useEffect } from "react";
import InputCode from "../components/InputCode";
import Spacer from "../components/Spacer";
import Button from "../components/Button";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";
import Snippet from "../components/Snippet";
import { Turnstile } from '@marsidev/react-turnstile';
import register from "../api/auth/register";
import login from "../api/auth/login.jsx";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";

const customStyles = {
  content: {
    backgroundColor: "#171717",
    borderWidth: "1px",
    borderColor: "#ECE9E610",
    padding: "20px",
    borderRadius: "8px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 999,
  },
};

// Анимация тряски
const shakeAnimation = {
  initial: { x: 0, scale: 0.9 },
  animate: {
    x: [0, -10, 10, -10, 10, 0, -10, 10, 0, -10, 10, 0],
    scale: 1,
    transition: { duration: 0.7, ease: "easeInOut" },
  },
};

function Login({ setError }) {
  const navigate = useNavigate();
  Modal.setAppElement("#root");

  const [jwt] = useLocalStorage("jwt", localStorage.getItem("jwt"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [token, setToken] = useState();
  const [inputToken, setInputToken] = useState("");
  const [registrationResult, setRegistrationResult] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (jwt) {
      navigate("/panel");
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (token && isModalOpen && !registrationResult) {
        try {
          setRegistrationResult(await register(token));
        } catch (err) {
          setError({ msg: err.response.data.message, code: err.response.status });
        }
      }
    }
    fetchData();
  }, [token, isModalOpen]);

  const logIn = async () => {
    try {
      localStorage.setItem("jwt", (await login(token, inputToken)).jwtToken);
      navigate("/panel");
    } catch (err) {
      setError({ msg: err.response.data.message, code: err.response.status });
    }
  };

  const openModal = async () => {
    setIsModalOpen(true);
    setIsButtonEnabled(false);
    setCountdown(5);
  };

  const closeModal = () => setIsModalOpen(false);

  React.useEffect(() => {
    let timer;
    if (isModalOpen) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsButtonEnabled(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isModalOpen]);

  const handleGotItClick = () => {
    if (registrationResult) {
      setInputToken(registrationResult.token);

      const tokenContent = `Your token: ${registrationResult.token}\n\nUse it at: https://overload.su/ or https://stresserx.su`;
      const blob = new Blob([tokenContent], { type: "text/plain" });

      const currentDate = new Date().toISOString().slice(0, 10); // Формат YYYY-MM-DD
      const fileName = `token-backup (${currentDate}).txt`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
    closeModal();
  };

  return (
    <div className="w-full h-screen bg-cover bg-center relative mt-[-48px]">
      <img
        src="https://i.imgur.com/S3oJIZG.png"
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
        style={{ mixBlendMode: "soft-light" }}
      />
      <div
        className={`absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center ${isModalOpen ? "backdrop-blur-sm" : ""
          }`}
      >
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-4xl text-white">OVERLOAD</h1>
          <p className="text-center text-secondary text-[14px] mt-[36px]">
            Want to have more options on website functionality?
            <br />
            For this you need to log in
          </p>
          <Spacer y={48} />
          <InputCode value={inputToken} onChange={(e) => setInputToken(e.target.value)} className="" />
          <Spacer y={24} />
          <Turnstile onSuccess={setToken} siteKey="0x4AAAAAAAhhsIjlCSnr8raX" />
          <Button
            disabled={!token}
            onClick={logIn}
            style={{ boxShadow: "0px 12px 36px 0px rgba(236, 233, 230, 0.25)" }}
          >
            {token ? "Login" : "Wait until we verify your browser"}
          </Button>
          <Spacer y={36} />
          <div className="flex items-center justify-center gap-3">
            <hr className="flex-grow border-t w-16 border-secondary" />
            <p className="text-secondary text-[13px] whitespace-nowrap">
              You don't have a token?
            </p>
            <hr className="flex-grow border-t w-16 border-secondary" />
          </div>
          <Spacer y={36} />
          <Button onClick={openModal} variant="secondary">
            Register
          </Button>
        </div>
      </div>
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-[#171717] border border-[#ECE9E610] p-6 rounded-lg shadow-lg"
              initial={{ y: "50%", opacity: 0 }}
              animate={{ y: "0%", opacity: 1 }}
              exit={{ y: "50%", opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={customStyles.content}
            >
              <div>
                <h2 className="text-xl text-center">ATTENTION!</h2>
                <Spacer y={36} />
                <motion.p
                  className="text-center font-bold text-rose-600"
                  initial="initial"
                  animate="animate"
                  variants={shakeAnimation}
                >
                  This is your account token.
                  <br />
                  No email, no usernames. Just anonymity.
                </motion.p>
                <p className="text-center text-sm">
                  It's the only identifier you need to use our service,
                  <br />
                  so <span className="font-bold text-rose-600">DON'T LOSE IT!</span>
                </p>
                <Spacer y={36} />
                <div className="flex justify-center">
                  {!registrationResult ? <Snippet text="Your token is loading..." /> : <Snippet text={registrationResult.token} />}
                </div>
                <Spacer y={24} />
                <div className="flex justify-center">
                  <Button
                    onClick={handleGotItClick} // Изменено на новую функцию
                    disabled={!isButtonEnabled}
                  >
                    {isButtonEnabled
                      ? "Ok, got it"
                      : `Please wait ${countdown}s`}
                  </Button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Login;
